import { h } from "preact";
import { Router } from "preact-router";

// Code-splitting is automated for `routes` directory
import Login from "../routes/login";

const App = () => (
  <div id="app">
    <main>
      <Router>
        <Login path="/" />
      </Router>
    </main>
  </div>
);

export default App;
